import { useState, useEffect, FunctionComponent } from "react";

import MainContainer from "../shared/components/main-container";
import Loading from "src/shared/components/loading";
import { Hub } from "@aws-amplify/core";
import checkAuthQuery from "./check.ts.graphql";
import Empty from "src/shared/components/empty";
import { Button } from "@learnerbly/web-components";
import qs from "qs";
import { useLocation } from "react-router-dom";
import { logError } from "../shared/util/error-logging";
import { configureAuth } from "../shared/util/auth";
import { useQuery } from "@apollo/client";
import { checkAuth as TCheckAuth } from "./__generated__/checkAuth";
import usePageView from "../shared/hooks/use-page-view";

let error = false;

const AuthCallback: FunctionComponent = () => {
  usePageView("Auth Callback");
  const [showReloadHint, setReloadHint] = useState(false);
  const { loading, data } = useQuery<TCheckAuth>(checkAuthQuery);
  const { search } = useLocation();
  const { error_description } = qs.parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    const timer = window.setTimeout(() => setReloadHint(true), 7000);
    return () => window.clearTimeout(timer);
  }, []);

  useEffect(() => {
    Hub.listen("auth", (data) => {
      const {
        payload: { event },
      } = data;

      if (event === "signIn") {
        return window.location.assign("/");
      }

      if (event === "signIn_failure") {
        error = true;
        logError(new Error(data.payload.data));
      }
    });

    configureAuth();
  });

  if (error) {
    if (error_description) {
      const [, message] = new String(error_description).split("error ");

      return (
        <MainContainer>
          <Empty emoji="🙈">
            <p>
              Drat! {message} Please check in with our admin, or contact us at{" "}
              <a href="mailto:support@learnerbly.com">support@learnerbly.com</a>{" "}
              if you continue to have issues logging in.
            </p>
          </Empty>
        </MainContainer>
      );
    }

    return (
      <MainContainer>
        <Empty emoji="🙈">
          <p>
            Drat! Something went wrong. Please try again. Contact us at{" "}
            <a href="mailto:support@learnerbly.com">support@learnerbly.com</a>{" "}
            if you continue to have issues logging in.
          </p>
        </Empty>
      </MainContainer>
    );
  }

  if (!loading && data?.user) {
    window.location.assign("/");
    return null;
  }

  const showHint = !loading && !data?.user && showReloadHint;

  if (showHint) {
    return (
      <MainContainer>
        <Empty emoji="🤔">
          <p>Seems like something timed out</p>
          <a href={process.env.APP_URL}>
            <Button>Try to login again</Button>
          </a>
        </Empty>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <Loading />
    </MainContainer>
  );
};

export default AuthCallback;
