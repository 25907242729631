import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import {
  isOrgAdmin,
  isApprover,
  hasAdHocRequests,
} from "src/shared/util/user-constraints";
import PropTypes from "prop-types";

import { track } from "src/shared/util/segment";
import { featureFlagNames } from "src/shared/util/feature-flags";

import { AppContext } from "src/app-context";

import OffPlatformResourceButton from "src/shared/components/off-platform-resource-button";
import RequestAnythingButton from "src/shared/components/request-anything-button";
import SearchForm from "../search-form";

import styles from "./styles.scss";

const HamburgerNavigation = () => {
  const { currentUser, setShowNavigation, showNavigation, orgPageUrl } =
    useContext(AppContext);

  const homepageOptimisationPhase1 = useFeatureIsOn(
    featureFlagNames.homepageOptimisationPhase1,
  );

  const trackClick = (title) => {
    setShowNavigation("default");
    track("Clicked Nav", {
      title,
    });
  };

  const links = [
    {
      to: "/",
      title: "Home",
      onClick: () => trackClick("Home"),
    },
    {
      to: "/explore/",
      title: "Explore",
      onClick: () => trackClick("Explore"),
    },
  ];

  if (homepageOptimisationPhase1) {
    links.push({
      to: "/recommended-playlists/",
      title: "Playlists",
      onClick: () => trackClick("Playlists"),
    });
  }

  if (isApprover(currentUser)) {
    links.push({
      to: "/approvals/",
      title: "Team",
      onClick: () => trackClick("Team"),
    });
  }

  if (isOrgAdmin(currentUser)) {
    links.push({
      to: `/${orgPageUrl}/`,
      title: "Admin",
      onClick: () => trackClick("Admin"),
    });
  }

  return (
    <>
      {showNavigation === "hamburger" && (
        <div className={styles.container} data-cy="hamburger-navigation">
          <div className={styles.navigation}>
            <div className={styles.searchBox}>
              <SearchForm onSearch={() => setShowNavigation("default")} />
            </div>

            {links.map(({ onClick, title, to }) => (
              <NavLink
                key={title}
                activeClassName={styles.active}
                className={styles.link}
                exact
                onClick={onClick}
                to={to}
              >
                {title}
              </NavLink>
            ))}

            <div className={styles.adhocRequests}>
              {hasAdHocRequests(currentUser) ? (
                <div className={styles.requestButton}>
                  <RequestAnythingButton />
                </div>
              ) : (
                <div className={styles.requestButton}>
                  <OffPlatformResourceButton trackClick={trackClick} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

HamburgerNavigation.propTypes = {
  location: PropTypes.object,
};

export default HamburgerNavigation;
