import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "@algolia/autocomplete-theme-classic/dist/theme.min.css";

import "whatwg-fetch";

import { useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { useFeatureIsOn, useGrowthBook } from "@growthbook/growthbook-react";

import * as Sentry from "@sentry/react";

import { AppContext } from "src/app-context";

import { isOnboarded } from "./shared/util/user-constraints";
import { ScrollToTop } from "./shared/components/scroll-to-top";
import { setupUserContext } from "./shared/util/error-logging";
import AppVersionChecker from "./shared/components/app-version-checker";
import ErrorMessage from "./shared/components/error";
import Footer from "./shared/components/footer";
import HamburgerNavigation from "./shared/components/mobile-hamburger-navigation";
import { default as NewHamburgerNavigation } from "./shared/components/HamburgerNavigation";
import Header from "./shared/components/header";
import { default as NewHeader } from "./shared/components/NewHeader";

import ImpersonateBanner from "./shared/components/impersonate-banner";
import Intercom from "./shared/components/intercom";
import Navigation from "./shared/components/navigation";
import ProfileNavigation from "./shared/components/mobile-profile-navigation";

import Routes from "./routes";
import styles from "./styles.scss";
import { ApolloContext } from "./apollo-context";
import { track } from "./shared/util/segment";
import { setFeatureFlags } from "./shared/util/feature-flags";

const AppHeader = () => {
  const hasPotsEnabled = useFeatureIsOn("pots");

  // This will be short lived whilst we experiment
  // with the new header for pots organisations only
  if (hasPotsEnabled) {
    return <NewHeader />;
  }
  return <Header />;
};

const MobileNavigation = () => {
  const hasPotsEnabled = useFeatureIsOn("pots");

  // This will be short lived whilst we experiment
  // with the new header for pots organisations only
  if (hasPotsEnabled) {
    return <NewHamburgerNavigation />;
  }
  return <HamburgerNavigation />;
};

const ApiUrlABTest = () => {
  const { apiUrl, setApiUrl } = useContext(ApolloContext);
  const hasFederatedGraphQLApi = useFeatureIsOn("federated_graphql_api");

  const newApiUrl = useMemo(() => {
    return hasFederatedGraphQLApi
      ? process.env.GATEWAY_API_URL
      : process.env.API_URL;
  }, [hasFederatedGraphQLApi]);

  useEffect(() => {
    if (apiUrl !== newApiUrl) {
      setApiUrl(newApiUrl);
    }
  }, [apiUrl, newApiUrl, setApiUrl]);

  return null;
};

const trackScrollEvent = () => {
  const scrollDepthPercent = Math.round(
    ((window.innerHeight + window.scrollY) / document.body.offsetHeight) * 100,
  );

  track("User Scrolled", {
    scrollDepthPixels: window.scrollY,
    scrollDepthPercent,
  });
};

const App = () => {
  const history = useHistory();
  const { currentUser, showNavigation, showFooter } = useContext(AppContext);
  const growthbook = useGrowthBook();

  useEffect(() => {
    // track user scroll events
    window.addEventListener("scrollend", trackScrollEvent);

    return () => {
      window.removeEventListener("scrollend", trackScrollEvent);
    };
  }, []);

  useEffect(() => {
    // Retrieve all features
    const allFeatures = growthbook.getFeatures();

    // Filter active features
    const activeFeatures = Object.entries(allFeatures).reduce((acc, [key]) => {
      const isFeatureOn = growthbook.isOn(key);
      if (isFeatureOn) {
        const featureValue = growthbook.getFeatureValue(key);
        acc[key] = featureValue;
      }
      return acc;
    }, {});

    // Saves to session storage for retrieval in track function
    setFeatureFlags(activeFeatures);
  }, [growthbook]);

  useEffect(() => {
    if (currentUser) {
      setupUserContext(currentUser);

      if (
        !isOnboarded(currentUser) &&
        !history.location.pathname.includes("onboarding")
      ) {
        // We remove any callback redirect for onboarding as we want to redirect to the onboarding page
        sessionStorage.removeItem("callback-redirect");

        history.push("/onboarding/get-started/");
        return;
      }

      const redirectUri = sessionStorage.getItem("callback-redirect");
      if (redirectUri) {
        sessionStorage.removeItem("callback-redirect");
        history.push(redirectUri);
      }
    }
  }, [currentUser, history]);

  const showImpersonate = currentUser && currentUser.isImpersonated;

  const classes = classnames({
    [styles.fixed]:
      showNavigation === "hamburger" || showNavigation === "profile",
  });

  return (
    <AppVersionChecker>
      <ApiUrlABTest />
      <ScrollToTop />
      <div className={classes}>
        {showNavigation && <AppHeader />}
        <div className={styles.appContainer}>
          <Intercom />
          <Navigation />
          {showImpersonate && <ImpersonateBanner />}
          <MobileNavigation />
          <ProfileNavigation />
          <Routes />
        </div>
        {showFooter && <Footer />}
      </div>
    </AppVersionChecker>
  );
};

export default Sentry.withErrorBoundary(App, { fallback: <ErrorMessage /> });
