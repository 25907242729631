import { graphql } from "../../../__generated__";

export const WHOAMI_QUERY = graphql(`
  query whoami {
    user {
      id
      email
      exchangeRates {
        GBP
        CAD
        EUR
        USD
        SEK
      }
      firstName
      lastName
      country
      locale
      admin
      organisationAdmin
      featureToggles
      createdAt
      onboarded
      hasPassword
      jobTitle
      isManager
      jobFamily
      jobFamilyOther
      jobLevel
      jobLevelOther
      jobProfileSkippedAt
      role {
        id
        name
        skills {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      intercomHmac
      hasCookieConsent
      startedPDP
      isImpersonated
      image
      currency
      groups {
        id
        name
      }
      pdpFocuses {
        id
      }
      goals {
        id
        dueDate
        status
      }
      approversOf(size: 100) {
        id
      }
      activeIndividualBudget {
        id
        total
        remaining
        spent
        currency
        budgetPeriodId
        endDate
      }
      organisation {
        id
        adhocRequests
        analyticsDashboard
        budgetPeriods {
          id
        }
        connectedStripeAccountId
        createdAt
        enabledFulfilledByLearnerRequestTypes
        hasAmazonRequests
        hasFulfilledByLearnerRequests
        hasFunds
        hasMagicLinks
        hasPots
        internalEvents
        internalLearning
        logo
        name
        recentActivity
        singleSignOn
        testClient
      }
      focuses {
        id
        subject {
          id
          name
          children {
            id
          }
        }
      }
      approvers {
        id
        firstName
        lastName
      }
      potsApproverOf(first: 1) {
        edges {
          node {
            id
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      pots {
        id
        name
        currency
        allocatedFunds
        totalSpend
        budget {
          endDate
        }
      }
      geographicLocation
      addresses {
        id
        city
        state
        country
        diallingCode
        postcode
        street
        telephone
      }
      tasks {
        approvals(size: 1) {
          id
          status
        }
      }
      hasProvidedConsentToShareContactDetails
      algoliaSearchKey
    }
  }
`);
