import { organisationsWithPrivacyToggle } from "./organisations-with-privacy-toggle";
import { whoami_user_potsApproverOf as PotsApproverOf } from "src/shared/components/authorizer/__generated__/whoami";

type UserAddress = {
  id?: string;
  city?: string;
  country?: string;
  state?: string;
  diallingCode?: string;
  postcode?: string;
  street?: string;
  telephone?: string;
};

type ActiveIndividualBudget = {
  id: string;
  total?: number;
  remaining?: number;
  currency?: Intl.NumberFormatOptions["currency"] | null;
  endDate?: string;
  spent?: number;
};

type Pot = {
  name: string;
  allocatedFunds: number;
  totalSpend: number;
  currency: string;
  budget?: {
    endDate: string;
  };
};

type Person = {
  id: string;
};

type Focus = {
  id: string;
  subject?: {
    id: string;
    name?: string;
  };
};

type Goal = {
  id: string;
  status: string;
  dueDate: string;
};

type Group = {
  archived?: boolean;
  id: string;
  name: string;
};

type BudgetPeriod = {
  id: string;
};

type Organisation = {
  adhocRequests?: boolean;
  analyticsDashboard?: boolean;
  budgetPeriods?: Array<BudgetPeriod>;
  createdAt?: string;
  id?: string;
  internalEvents?: boolean;
  internalLearning?: boolean;
  logo?: string;
  name?: string;
  recentActivity?: boolean;
  testClient?: boolean;
  hasAmazonRequests?: boolean;
  hasPots?: boolean;
  hasFulfilledByLearnerRequests?: boolean;
  enabledFulfilledByLearnerRequestTypes?: Array<FulfilledByLearnerRequestType>;
};

export type User = {
  id: string;
  activeIndividualBudget: ActiveIndividualBudget;
  addresses: Array<UserAddress>;
  admin: boolean;
  algoliaSearchKey: string;
  approvers: Array<Person>;
  approversOf: Array<Person>;
  country: string;
  createdAt: string;
  currency: string;
  email: string;
  featureToggles: Array<string>;
  firstName: string;
  focuses: Array<Focus>;
  geographicLocation: string;
  goals: Array<Goal>;
  groups: Array<Group>;
  hasCookieConsent: boolean;
  hasPassword: boolean;
  image: string;
  jobFamily: string | null;
  jobFamilyOther: string;
  jobLevel: string;
  jobLevelOther: string;
  jobTitle: string;
  intercomHmac: string;
  isImpersonated: boolean;
  isManager: boolean;
  lastName: string;
  locale: string;
  onboarded: boolean;
  orgPageUrl: string;
  organisation: Organisation;
  organisationAdmin: boolean;
  startedPDP: boolean;
  potsApproverOf: PotsApproverOf;
  pots: Array<Pot>;
  role: {
    id: string;
    name: string;
    skills: {
      edges: Array<{ node: { id: string; name: string } }>;
    };
  } | null;
};

export type Role = "Admin" | "Approver" | "Learner";

export enum FulfilledByLearnerRequestType {
  EXPENSES = "EXPENSES",
  VIRTUAL_CARDS = "VIRTUAL_CARDS",
}

const potsDemoOrganisations = [
  "b06efe8c-436a-4ee5-ac9c-f10b9606723c",
  "2426c33e-05c2-4193-9073-7ba6ea3d92e1",
  "42061071-3416-4c64-a677-51542067b897",
];

export type UserForConstraints = Partial<User>;

export const isOrgAdmin = (user: UserForConstraints = {}): boolean =>
  user && !!user.organisationAdmin;

export const isAdmin = (user: UserForConstraints = {}): boolean =>
  user && !!user.admin;

export const isOnboarded = (user: UserForConstraints = {}): boolean =>
  !!(user.onboarded || (user.focuses && !!user.focuses.length));

export const isApprover = (user: UserForConstraints = {}): boolean =>
  !!(user && user.approversOf && user.approversOf.length > 0);

export const isPotsApprover = (user: UserForConstraints = {}): boolean =>
  !!(user && user.potsApproverOf && user.potsApproverOf.edges.length > 0);

export const isInPotsOrganisation = (user: UserForConstraints = {}): boolean =>
  !!(user && user.organisation && user.organisation.hasPots);

export const isInDemoOrganisation = (user: UserForConstraints = {}): boolean =>
  !!(
    user &&
    user.organisation &&
    user.organisation.id &&
    potsDemoOrganisations.includes(user.organisation.id)
  );

export const hasAnalyticsDashboard = (user: UserForConstraints = {}): boolean =>
  !!(
    user.organisation &&
    user.organisation.analyticsDashboard &&
    (isApprover(user) || isOrgAdmin(user))
  );

export const hasInternalLearning = (user: UserForConstraints = {}): boolean =>
  !!(user && user.organisation && user.organisation.internalLearning);

export const hasInternalEvents = (user: UserForConstraints = {}): boolean =>
  !!(user && user.organisation && user.organisation.internalEvents);
export const hasRecentActivity = (user: UserForConstraints = {}): boolean =>
  !!(user && user.organisation && user.organisation.recentActivity);

export const hasAdHocRequests = (user: UserForConstraints = {}): boolean =>
  !!(
    user &&
    user.organisation &&
    user.organisation.adhocRequests &&
    user.country !== "QQ"
  );

const hasAmazonContentRegion = (user: UserForConstraints = {}): boolean =>
  !!(user.country && ["DE", "ES", "GB", "US"].includes(user?.country));

export const hasAmazonRequests = (user: UserForConstraints = {}): boolean =>
  !!(hasAmazonContentRegion(user) && user?.organisation?.hasAmazonRequests);

export const hasTeamGoalsModule = (user: UserForConstraints = {}): boolean =>
  user && isApprover(user);

export const hasStartedPDP = (user: UserForConstraints = {}): boolean =>
  !!(user && user.startedPDP);

export const hasHomeGoalsModule = (user: UserForConstraints = {}): boolean =>
  !!(
    hasStartedPDP(user) &&
    user.goals &&
    user.goals.some(
      ({ status, dueDate }) =>
        status === "OPEN" && +new Date(dueDate) > Date.now(),
    )
  );

export const hasVirtualCardsToggle = (user: UserForConstraints = {}): boolean =>
  (user?.organisation?.enabledFulfilledByLearnerRequestTypes?.includes(
    FulfilledByLearnerRequestType.VIRTUAL_CARDS,
  ) ||
    !!user?.organisation?.hasFulfilledByLearnerRequests) && // hasFulfilledByLearnerRequests check is temporary
  user.currency === "GBP"; // Currency check is temporary

export const hasExpenseToggle = (user: UserForConstraints = {}): boolean =>
  user?.organisation?.enabledFulfilledByLearnerRequestTypes?.includes(
    FulfilledByLearnerRequestType.EXPENSES,
  ) ?? false;

export const hasToProvideConsentToShareContactDetails = (
  user: UserForConstraints = {},
): boolean => {
  if (
    user?.organisation?.id &&
    organisationsWithPrivacyToggle.includes(user.organisation.id)
  ) {
    return true;
  }

  return false;
};

export const hasMerge = (user: UserForConstraints = {}): boolean =>
  !!user.featureToggles?.includes("MERGE_HRIS_INTEGRATION");

export const META_ORG_ID = "1eb6c1c5-90ac-4256-ae76-30251cad5a5b";

export const shouldRequestBeDefaultPublic = (
  user: UserForConstraints = {},
): boolean => user?.organisation?.id !== META_ORG_ID;

export const hasSubjectSearchFilters = (
  user: UserForConstraints = {},
): boolean =>
  !!user.featureToggles?.includes("SUBJECT_SEARCH_FILTERS_EXPERIMENT");

export const hasResourceTypeView = (user: UserForConstraints = {}): boolean =>
  !!user.featureToggles?.includes("RESOURCE_TYPE_VIEW");

export const hasExperiments = (user: UserForConstraints = {}): boolean =>
  !user.organisation?.testClient;

export const hasTeamMemberView = (user: UserForConstraints = {}): boolean =>
  !!user.featureToggles?.includes("TEAM_MEMBER_VIEW");

export const hasResourceRelevance = (user: UserForConstraints = {}): boolean =>
  !!user.featureToggles?.includes("RESOURCE_RELEVANCE");

export const hasRequestByVirtualCard = (
  user: UserForConstraints = {},
): boolean => hasVirtualCardsToggle(user);

export const hasVirtualCardOnlyProducts = (
  user: UserForConstraints = {},
): boolean => !!user.featureToggles?.includes("VC_ONLY_PRODUCTS");

export const hasSelfServePlaylist = (user: UserForConstraints = {}): boolean =>
  !!user.featureToggles?.includes("SELF_SERVE_PLAYLISTS");

export const getRole = (currentUser: UserForConstraints): Role => {
  if (isOrgAdmin(currentUser)) {
    return "Admin";
  }
  if (isApprover(currentUser)) {
    return "Approver";
  }
  if (isPotsApprover(currentUser)) {
    return "Approver";
  }
  return "Learner";
};

export const hasJobProfile = (user: UserForConstraints): boolean => {
  if (user?.jobFamily === "other" && !user?.jobFamilyOther) {
    return false;
  }

  if (user?.jobLevel === "other" && !user?.jobLevelOther) {
    return false;
  }

  return (
    Boolean(user?.jobFamily) &&
    Boolean(user?.jobLevel) &&
    Boolean(user?.isManager !== null)
  );
};

export const hasRequestAnythingOption = (
  user: UserForConstraints = {},
): boolean => {
  if (
    hasAmazonRequests(user) ||
    hasVirtualCardsToggle(user) ||
    hasExpenseToggle(user)
  ) {
    return true;
  }
  return false;
};
