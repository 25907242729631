import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { track } from "../../../../util/segment";

import styles from "./styles.scss";

export const JobFamilyNavigation: FunctionComponent = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const container = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (container.current === null) {
        return;
      }

      const currentScrollPos = window.pageYOffset;

      if (prevScrollPos > currentScrollPos) {
        container.current.style.marginTop = "-1px";
      } else {
        container.current.style.marginTop = "-200px";
      }

      setPrevScrollPos(currentScrollPos);
    };

    if (container.current) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const trackCategory = (title: string) => {
    track("Clicked Nav", {
      title,
    });
  };

  return (
    <div ref={container} className={styles.jobFamilyNavigation}>
      <Link onClick={() => trackCategory("data category")} to={"/role/data/"}>
        Data
      </Link>
      <Link
        onClick={() => trackCategory("people category")}
        to={"/role/people/"}
      >
        People
      </Link>
      <Link
        onClick={() => trackCategory("product category")}
        to={"/role/product/"}
      >
        Product
      </Link>
      <Link onClick={() => trackCategory("sales category")} to={"/role/sales/"}>
        Sales
      </Link>
      <Link
        onClick={() => trackCategory("software-engineering category")}
        to={"/role/software-engineering"}
      >
        Software Engineering
      </Link>
    </div>
  );
};
