import InfiniteScroll from "react-infinite-scroller";
import noop from "lodash/noop";
import PropTypes from "prop-types";

import InternalEventCard from "../internal-event-card";
import Loading from "../loading";
import ResourceCard from "../resource-card";
import styles from "./styles.scss";

export const InfiniteResources = ({
  resources,
  loadMoreEntries,
  hasMore,
  trackContext,
  enableFeedback = false,
  getAdditionalTrackingContextForResource,
  callbacks = {},
  highlightedResources,
}) => (
  <InfiniteScroll
    pageStart={0}
    loadMore={loadMoreEntries || noop}
    hasMore={hasMore}
    loader={<Loading key="loading" />}
    className={styles.container}
  >
    {resources.map((resource, index) => (
      <div key={resource.id} className={styles.card}>
        {resource.isInternalEvent ? (
          <InternalEventCard
            resource={resource}
            trackPosition={index + 1}
            trackContext={trackContext}
          />
        ) : (
          <ResourceCard
            additionalTrackingContext={
              getAdditionalTrackingContextForResource
                ? getAdditionalTrackingContextForResource(resource)
                : {}
            }
            callbacks={callbacks}
            resource={resource}
            trackPosition={index + 1}
            trackContext={trackContext}
            enableFeedback={enableFeedback}
            highlight={highlightedResources?.includes(resource.id)}
          />
        )}
      </div>
    ))}
  </InfiniteScroll>
);

InfiniteResources.propTypes = {
  hasMore: PropTypes.bool,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      bookmarked: PropTypes.bool,
      cheapestProduct: PropTypes.shape({
        id: PropTypes.string,
        localisedPrice: PropTypes.shape({
          currency: PropTypes.string,
          amount: PropTypes.number,
        }),
      }),
      description: PropTypes.string,
      enquirable: PropTypes.bool,
      id: PropTypes.string,
      image: PropTypes.string,
      image2x1: PropTypes.string,
      isInternalEvent: PropTypes.bool,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
        }),
      ),
      subjects: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
      summary: PropTypes.string,
      supplier: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      title: PropTypes.string,
      type: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ),
  highlightedResources: PropTypes.arrayOf(PropTypes.string),
  loadMoreEntries: PropTypes.func,
  trackContext: PropTypes.string,
  enableFeedback: PropTypes.bool,
  getAdditionalTrackingContextForResource: PropTypes.func,
  callbacks: PropTypes.shape({ toggleResourceBookmark: PropTypes.func }),
};

export default InfiniteResources;
