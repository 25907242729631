import { useContext } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import { AppContext } from "src/app-context";
import { isApprover, isOrgAdmin } from "src/shared/util/user-constraints";
import HeaderContent from "./headerContent";
import { SecondaryHeaderMenuBar } from "./components/SecondaryHeaderMenuBar";

import styles from "./styles.scss";

export const PageLink = ({ to, children, exact, isActive, onClick }) => (
  <>
    <NavLink
      activeClassName={styles.active}
      className={styles.link}
      to={to}
      exact={exact}
      isActive={isActive}
      onClick={onClick}
    >
      <div className={styles.linktext}>{children}</div>
    </NavLink>
  </>
);

PageLink.defaultProps = {
  exact: false,
};

PageLink.propTypes = {
  to: PropTypes.string,
  exact: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isActive: PropTypes.func,
  onClick: PropTypes.func,
};

export const Header = () => {
  const { currentUser } = useContext(AppContext);

  if (!currentUser) {
    return null;
  }

  const collapseLinksEarly = isApprover(currentUser) || isOrgAdmin(currentUser);

  return (
    <header className={classNames(styles.homepagePhase1, styles.header)}>
      <nav
        className={classNames(
          styles.navigation,
          collapseLinksEarly && styles.collapseLinksEarly,
        )}
      >
        <div className={styles.mainRow}>
          <HeaderContent />
        </div>

        <SecondaryHeaderMenuBar />
      </nav>
    </header>
  );
};

export default Header;
