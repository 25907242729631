import { FunctionComponent } from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { differenceInDays } from "date-fns";

import { useBudgetData } from "../../../../hooks/use-budget-data";
import { featureFlagNames } from "../../../../util/feature-flags";
import { BudgetReminderBanner } from "../BudgetReminderBanner/BudgetReminderBanner";
import { JobFamilyNavigation } from "../JobFamilyNavigation/JobFamilyNavigation";

import styles from "./styles.scss";
import { useAppContext } from "../../../../../app-context";
import {
  isOrgAdmin,
  UserForConstraints,
} from "../../../../util/user-constraints";
import { LabsROIBanner } from "../LabsROIBanner";
import { useRouteMatch } from "react-router-dom";

const BUDGET_REMINDER_BANNER_DAYS_THRESHOLD = 60;

export const SecondaryHeaderMenuBar: FunctionComponent = () => {
  const { currentUser } = useAppContext() as {
    currentUser?: UserForConstraints;
  };

  const budgetData = useBudgetData();
  const homepageOptimisationPhase1 = useFeatureIsOn(
    featureFlagNames.homepageOptimisationPhase1,
  );
  const budgetReminderBannerValue = useFeatureIsOn(
    featureFlagNames.budgetReminderBanner,
  );
  const labsROIBannerValue = useFeatureIsOn(featureFlagNames.labsROIBanner);
  const labsROIPathMatch = useRouteMatch("/labs/roi");

  const shouldShowLabsROIBanner =
    !!labsROIBannerValue && !labsROIPathMatch && isOrgAdmin(currentUser);

  const shouldShowBudgetReminderBanner =
    !shouldShowLabsROIBanner &&
    !!budgetReminderBannerValue &&
    !!budgetData &&
    budgetData.remainingBudgetAmount > 0 &&
    differenceInDays(budgetData.endDate, new Date()) <
      BUDGET_REMINDER_BANNER_DAYS_THRESHOLD;

  const shouldShowJobFamilyNavigation =
    !!homepageOptimisationPhase1 && !shouldShowBudgetReminderBanner;

  if (shouldShowLabsROIBanner) {
    return (
      <div className={styles.subRow}>
        <LabsROIBanner />
      </div>
    );
  }

  if (shouldShowBudgetReminderBanner) {
    return (
      <div className={styles.subRow}>
        <BudgetReminderBanner budgetData={budgetData} />
      </div>
    );
  }

  if (shouldShowJobFamilyNavigation) {
    return (
      <div className={styles.subRow}>
        <JobFamilyNavigation />
      </div>
    );
  }

  return null;
};
