import { Link } from "react-router-dom";
import { FunctionComponent, useContext } from "react";
import { useFeatureValue } from "@growthbook/growthbook-react";

import { hasRequestAnythingOption } from "../../util/user-constraints";
import { featureFlagNames } from "../../util/feature-flags";
import { track } from "../../util/segment";
import { AppContext } from "../../../app-context";
import styles from "./styles.scss";

const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const OffPlatformResourceLink: FunctionComponent<{
  location: "header" | "homepage" | "footer";
}> = ({ location }) => {
  const { currentUser } = useContext(AppContext);

  const offPlatformResourceButtonPosition = useFeatureValue<
    string | Array<string>
  >(featureFlagNames.offPlatformResourceButtonLocation, "header");

  if (!currentUser) {
    return null;
  }

  if (!hasRequestAnythingOption(currentUser)) {
    return null;
  }

  if (
    Array.isArray(offPlatformResourceButtonPosition) &&
    !offPlatformResourceButtonPosition.includes(location)
  ) {
    return null;
  }

  if (
    typeof offPlatformResourceButtonPosition === "string" &&
    offPlatformResourceButtonPosition !== location
  ) {
    return null;
  }

  return (
    <Link
      to="/request/resource-not-on-learnerbly/"
      className={`${styles.link}`}
      onClick={() =>
        track(`Clicked ${capitalize(location)} Nav Link`, {
          title: "Resource not on Learnerbly",
        })
      }
    >
      Resource not on Learnerbly?
    </Link>
  );
};
