import { FunctionComponent } from "react";
import moment from "moment";

import styles from "./styles.scss";
import { useAppContext } from "src/app-context";
import { OffPlatformResourceLink } from "../off-platform-resource-link/OffPlatformResourceLink";

const currentYear = moment().year();

export const Footer: FunctionComponent = () => {
  const { currentUser } = useAppContext();

  return (
    <footer className={styles.container}>
      <div className={styles.content}>
        <span>
          © Learnerbly {currentYear}. All rights reserved.
          <a
            className={styles.link}
            rel="noopener noreferrer"
            href="https://learnerbly.trustkeith.co/"
          >
            Privacy
          </a>
          and
          <a
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.learnerbly.com/terms-and-conditions"
          >
            Terms
          </a>
        </span>

        <div className={styles.linkList}>
          <OffPlatformResourceLink location="footer" />

          <a className={styles.link} href="https://help.learnerbly.com/en/">
            Help Centre
          </a>
          {/* Check for current user as we show this on the login page */}
          {currentUser && (
            <a
              className={`${styles.link}`}
              href={`https://airtable.com/apphQp2ODD3fQZsIs/shrLVUkWXlqZvI4Cj?prefill_Name=${currentUser.firstName}+${currentUser.lastName}&prefill_Organisation=${currentUser.organisation.name}&prefill_Email=${currentUser.email}`}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Feedback
            </a>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
