import { FunctionComponent } from "react";

import styles from "./styles.scss";
import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { differenceInWeeks } from "date-fns";
import { BudgetData } from "../../../../hooks/use-budget-data";

export const BudgetReminderBanner: FunctionComponent<{
  budgetData: BudgetData;
}> = ({ budgetData }) => {
  const remainingWeeks = differenceInWeeks(budgetData.endDate, new Date());

  return (
    <div
      className={styles.budgetReminderBanner}
      data-testid="budget-reminder-banner"
    >
      <div className={styles.inner}>
        <FontAwesomeIcon size="lg" icon={faHourglassHalf} />
        <p>
          Time’s ticking – make the most of your budget before it resets in{" "}
          <strong>
            {remainingWeeks} week{remainingWeeks === 1 ? "" : "s"}
          </strong>
          !
        </p>
      </div>
    </div>
  );
};
