import { FunctionComponent, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { track } from "src/shared/util/segment";

import {
  isApprover,
  hasAdHocRequests,
  hasStartedPDP,
  isOrgAdmin,
} from "src/shared/util/user-constraints";
import { faFlagCheckered, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Logo from "../logo";
import OffPlatformResourceButton from "src/shared/components/off-platform-resource-button";
import ProfileNavigation from "../profile-navigation-widget";
import RequestAnythingButton from "src/shared/components/request-anything-button";
import { Text } from "@learnerbly/web-components";
import SearchForm from "../search-form";
import styles from "./styles.scss";
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react";
import { AppContext } from "src/app-context";
import useMediaQuery from "src/shared/hooks/use-media-query";
import { useBudgetData } from "src/shared/hooks/use-budget-data";
import { featureFlagNames } from "src/shared/util/feature-flags";
import { PageLink } from "./header";

const HeaderContent: FunctionComponent = () => {
  const isDesktop = useMediaQuery("(min-width: 1184px)");
  const fixedAdminRoutesActive = useFeatureIsOn("fixed_admin_routes");

  const homepageOptimisationPhase1 = useFeatureIsOn(
    featureFlagNames.homepageOptimisationPhase1,
  );

  const offPlatformResourceButtonPosition = useFeatureValue<
    string | Array<string>
  >(featureFlagNames.offPlatformResourceButtonLocation, "header");

  const { currentUser, orgPageUrl, setShowNavigation, showNavigation } =
    useContext(AppContext);

  const { pathname } = useLocation();
  const budgetData = useBudgetData();

  const {
    activeIndividualBudget,
    organisation: { logo },
    tasks: { approvals },
  } = currentUser;

  const openGoals = currentUser.goals
    ? currentUser.goals.filter(
        (goal: { status: string }) => goal.status === "OPEN",
      )
    : [];

  const hasPendingApprovals = approvals.length > 0;

  const trackClick = (title: string) =>
    track("Clicked Nav", {
      title,
    });

  const adminPath = fixedAdminRoutesActive ? "admin" : orgPageUrl;

  const shouldShowOffPlatformResourceButtonPosition = Array.isArray(
    offPlatformResourceButtonPosition,
  )
    ? offPlatformResourceButtonPosition.includes("header")
    : offPlatformResourceButtonPosition === "header";

  return (
    <>
      <div data-cy="logo" className={styles.left}>
        <FontAwesomeIcon
          onClick={() =>
            setShowNavigation(
              showNavigation === "hamburger" ? "default" : "hamburger",
            )
          }
          className={styles.menu}
          icon={faBars}
        />
      </div>
      <div className={styles.middle}>
        <div className={styles.logo}>
          <Link
            to="/"
            aria-hidden={isDesktop}
            tabIndex={isDesktop ? -1 : 0}
            aria-label="Learnerbly home"
          >
            {logo ? <img src={logo} alt="logo" /> : <Logo />}
          </Link>
        </div>
        <div className={styles.links}>
          <PageLink to="/" exact onClick={() => trackClick("Home")}>
            Home
          </PageLink>
          <PageLink to={"/explore/"} onClick={() => trackClick("Explore")}>
            Explore
          </PageLink>
          {homepageOptimisationPhase1 && (
            <PageLink
              to={"/recommended-playlists/"}
              onClick={() => trackClick("Playlists")}
            >
              Playlists
            </PageLink>
          )}
          {isApprover(currentUser) && (
            <PageLink
              to="/approvals/"
              isActive={() =>
                pathname === "/approvals/" || pathname === "/approvals/history/"
              }
              onClick={() => trackClick("Team")}
            >
              <div className={styles.notificationWrapper}>
                Team
                {hasPendingApprovals && <div className={styles.pendingBadge} />}
              </div>
            </PageLink>
          )}
          {isOrgAdmin(currentUser) && (
            <PageLink
              exact
              to={`/${adminPath}/`}
              isActive={() =>
                pathname === `/${adminPath}/` ||
                pathname === `/${adminPath}/account/` ||
                pathname === `/${adminPath}/content/` ||
                pathname === `/${adminPath}/events/` ||
                pathname === `/${adminPath}/people/` ||
                pathname === `/${adminPath}/groups/` ||
                pathname === `/${adminPath}/requests/` ||
                pathname === `/${adminPath}/settings/`
              }
              onClick={() => trackClick("Admin")}
            >
              Admin
            </PageLink>
          )}
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.search}>
          <SearchForm />
        </div>

        {hasAdHocRequests(currentUser) && (
          <div className={styles.requestButton}>
            <RequestAnythingButton />
          </div>
        )}

        {!hasAdHocRequests(currentUser) &&
          shouldShowOffPlatformResourceButtonPosition && (
            <div className={styles.requestButton}>
              <OffPlatformResourceButton trackClick={trackClick} />
            </div>
          )}

        {hasStartedPDP(currentUser) && (
          <Link to="/goals/" className={styles.goals}>
            <FontAwesomeIcon
              icon={faFlagCheckered}
              className={styles.goalIcon}
            />
            <Text textStyle="small" textVariant="bold">
              <span className={styles.goalsLabel}>
                {`${openGoals.length} goal${openGoals.length !== 1 ? "s" : ""}`}
              </span>
            </Text>
          </Link>
        )}

        {budgetData && (
          <div className={styles.budgetContainer}>
            {activeIndividualBudget && (
              <div className={styles.budget}>
                <Text textStyle="small" textVariant="bold">
                  {budgetData.formattedRemainingBudget}
                </Text>
              </div>
            )}
            <div className={styles.budgetOverlay}>
              <Text textStyle="small">{budgetData.formattedClaimMessage}</Text>
            </div>
          </div>
        )}
        <ProfileNavigation />
      </div>
    </>
  );
};

export default HeaderContent;
