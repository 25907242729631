import { FunctionComponent } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { useAppContext } from "../../../app-context";

export const AuthenticatedRoute: FunctionComponent<RouteProps> = ({
  children,
  ...props
}) => {
  const { pathname, search } = useLocation();

  const { currentUser } = useAppContext();

  if (!currentUser) {
    const loginUrl =
      pathname !== "/login"
        ? `/login?redirectUri=${pathname}${search}`
        : "/login";

    return <Redirect to={loginUrl} />;
  }

  return <Route {...props}>{children}</Route>;
};
