import { FunctionComponent } from "react";
import { Button } from "@learnerbly/web-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { useAppContext } from "../../../../../app-context";
import { UserForConstraints } from "../../../../util/user-constraints";
import { track } from "../../../../util/segment";

import styles from "./styles.scss";

export const LabsROIBanner: FunctionComponent = () => {
  const { currentUser } = useAppContext() as {
    currentUser?: UserForConstraints;
  };

  if (!currentUser || !currentUser.organisation) {
    return null;
  }

  return (
    <Link to="/labs/roi" onClick={() => track("Labs ROI banner clicked")}>
      <div data-testid="labs-roi-banner" className={styles.labsROIBanner}>
        <div className={styles.inner}>
          <div className={styles.text}>
            <span className={styles.label}>
              <FontAwesomeIcon icon={faChartLine} /> New for admins
            </span>
            <p>
              {" "}
              Get deep insights on the ROI of Learnerbly at{" "}
              {currentUser.organisation.name}
            </p>
          </div>

          <Button secondary>
            Get started <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
      </div>
    </Link>
  );
};
