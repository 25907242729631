import moment from "moment";
import { useContext } from "react";
import { WhoamiQuery } from "src/__generated__/graphql";
import { AppContext } from "src/app-context";
import { formatCurrencyValue } from "src/shared/util/text-display";

export type BudgetData = {
  formattedRemainingBudget: string;
  formattedClaimMessage: string;
  remainingBudgetAmount: number;
  endDate: Date;
};

type WhoamiUser = NonNullable<WhoamiQuery["user"]>;
type WhoamiPot = NonNullable<WhoamiUser["pots"]>[0];
type WhoamiPLB = WhoamiUser["activeIndividualBudget"];

const normalizeBudget = (locale: WhoamiUser["locale"]) => {
  const emptyBudgetClaim = "You have no budget left to spend 😥";

  const normalizePot = (pot?: WhoamiPot): BudgetData | null => {
    if (!pot || !pot.budget) {
      return null;
    }

    const remaining = Math.max(0, pot.allocatedFunds + pot.totalSpend);
    const formattedRemaining = formatCurrencyValue(
      remaining,
      pot.currency,
      locale,
    );
    const endDate = moment(pot.budget.endDate);

    return {
      formattedRemainingBudget: formattedRemaining,
      formattedClaimMessage:
        remaining <= 0
          ? emptyBudgetClaim
          : `There is ${formattedRemaining} left to spend in the ${
              pot.name
            } Pot until ${endDate.format("D MMMM YYYY")} 😄`,
      remainingBudgetAmount: remaining,
      endDate: endDate.toDate(),
    };
  };

  const normalizePLB = (plb?: WhoamiPLB): BudgetData | null => {
    if (!plb) {
      return null;
    }

    const remaining = Math.max(0, plb.remaining ?? 0);
    const formattedRemaining = formatCurrencyValue(
      remaining,
      plb.currency,
      locale,
    );
    const endDate = moment(plb.endDate).format("D MMMM YYYY");

    return {
      formattedRemainingBudget: formattedRemaining,
      formattedClaimMessage:
        remaining <= 0
          ? emptyBudgetClaim
          : `You have ${formattedRemaining} left to spend until ${endDate} 😄`,
      remainingBudgetAmount: remaining,
      endDate: moment(plb.endDate).toDate(),
    };
  };

  return {
    normalizePot,
    normalizePLB,
  };
};

export const useBudgetData = (): BudgetData | null => {
  const { currentUser } = useContext(AppContext);

  if (!currentUser) {
    return null;
  }

  const normalizeBudgetData = normalizeBudget(currentUser.locale);

  // Pots
  if (
    currentUser.organisation.hasPots &&
    currentUser.pots &&
    currentUser.pots.length > 0
  ) {
    return normalizeBudgetData.normalizePot(currentUser.pots[0]);
  }

  // PLB
  if (currentUser.activeIndividualBudget) {
    return normalizeBudgetData.normalizePLB(currentUser.activeIndividualBudget);
  }

  return null;
};
