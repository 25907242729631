import { FunctionComponent, useContext, useState } from "react";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import {
  useFeature,
  useFeatureIsOn,
  useFeatureValue,
} from "@growthbook/growthbook-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import copy from "copy-to-clipboard";
import { Text } from "@learnerbly/web-components";

import { AppContext } from "../../../app-context";
import UserImage from "../user-image/user-image";
import JobFamilyBadge from "../job-family-badge";

import { getToken } from "src/shared/util/auth";
import { track } from "src/shared/util/segment";
import {
  hasSelfServePlaylist,
  hasJobProfile,
  isAdmin,
  hasRequestAnythingOption,
  User,
} from "src/shared/util/user-constraints";
import { getJobFamilyRoute } from "src/shared/util/job-profile";
import { featureFlagNames } from "src/shared/util/feature-flags";
import orgIcon from "src/shared/assets/office-icon.svg";

import styles from "./styles.scss";

const copyToken = async () => {
  const token = await getToken();
  copy(token);
};

interface Props {
  onResetDemoData: () => Promise<void>;
  shouldShowResetDemoDataOption: boolean;
}

const shouldShowOffPlatformResourceLink = (
  currentUser: User,
  flagValue: string | Array<string>,
) => {
  if (!hasRequestAnythingOption(currentUser)) {
    return false;
  }

  if (Array.isArray(flagValue) && !flagValue.includes("nav-dropdown")) {
    return false;
  }

  if (typeof flagValue === "string" && flagValue !== "nav-dropdown") {
    return false;
  }

  return true;
};

const ProfileNavigationWidget: FunctionComponent<Props> = ({
  onResetDemoData,
  shouldShowResetDemoDataOption,
}) => {
  const { currentUser, orgPageUrl, setShowNavigation, showNavigation } =
    useContext(AppContext);

  const [overlayVisible, setOverlayVisible] = useState(false);

  const showOverlay = () => setOverlayVisible(true);
  const hideOverlay = () => setOverlayVisible(false);

  const overlayClasses = classnames(styles.overlay, {
    [styles.hidden]: !overlayVisible,
  });

  const hasJobProfileCTAToggle = useFeature("job_profile_cta").value;
  const hasCompletedJobProfile = hasJobProfile(currentUser);
  const showUpdateProfileCTA =
    hasJobProfileCTAToggle && !hasCompletedJobProfile;

  const hasJobFamilyPageToggle = useFeature("job_family_page").value;
  const jobFamilyRoute = getJobFamilyRoute(currentUser.jobFamily);
  const showJobFamilyPageLink =
    hasJobFamilyPageToggle && hasCompletedJobProfile && jobFamilyRoute;

  const hasLearningPathways = useFeatureIsOn("learning_pathways");

  const offPlatformResourceButtonPosition = useFeatureValue<
    string | Array<string>
  >(featureFlagNames.offPlatformResourceButtonLocation, "header");

  return (
    <OutsideClickHandler onOutsideClick={hideOverlay}>
      <div
        className={styles.container}
        onClick={overlayVisible ? hideOverlay : showOverlay}
      >
        <div className={styles.profileWrapper}>
          <div
            onClick={() =>
              setShowNavigation(
                showNavigation === "profile" ? "default" : "profile",
              )
            }
            className={styles.mobileImageContainer}
          >
            <UserImage
              firstName={currentUser.firstName}
              image={currentUser.image}
              lastName={currentUser.lastName}
            />
            {showUpdateProfileCTA && <div className={styles.notificationDot} />}
          </div>
          <div className={styles.imageContainer}>
            <UserImage
              firstName={currentUser.firstName}
              image={currentUser.image}
              lastName={currentUser.lastName}
            />
            {showUpdateProfileCTA && <div className={styles.notificationDot} />}
          </div>
          <FontAwesomeIcon
            className={styles.profileDownArrow}
            icon={faAngleDown}
          />
        </div>
        <nav className={overlayClasses}>
          <div className={styles.header}>
            <div className={styles.name}>
              {currentUser.firstName} {currentUser.lastName}
            </div>
            <Link
              to={`/explore/${orgPageUrl}`}
              className={styles.organisation}
              onClick={() =>
                track("Clicked Subnav", {
                  context: "User Menu",
                  title: "Company",
                })
              }
            >
              <img
                className={styles.linkIcon}
                src={orgIcon}
                alt="organisation"
              />
              {currentUser.organisation.name}
            </Link>
            {showJobFamilyPageLink && (
              <Link
                to={jobFamilyRoute}
                className={styles.jobFamilyLink}
                onClick={() =>
                  track("Clicked Job Family Link", {
                    context: "User Menu",
                  })
                }
              >
                <JobFamilyBadge jobFamily={currentUser.jobFamily} />
              </Link>
            )}
          </div>
          <div className={styles.content}>
            <ul className={styles.links}>
              <li>
                <Link to="/me" className={styles.link} onClick={hideOverlay}>
                  <span className={styles.linkText}>Profile</span>
                </Link>

                {showUpdateProfileCTA && (
                  <Link
                    to={"/me/edit"}
                    className={styles.updateProfile}
                    onClick={() => track("Update Profile Button Clicked")}
                  >
                    <Text textStyle="small" textVariant="bold">
                      Update profile
                    </Text>
                  </Link>
                )}
              </li>
              <li>
                <Link
                  to="/requests/"
                  className={styles.link}
                  onClick={hideOverlay}
                >
                  <span className={styles.linkText}>Requests</span>
                </Link>
              </li>
              {hasLearningPathways && (
                <li>
                  <Link
                    to="/me/pathways"
                    className={styles.link}
                    onClick={hideOverlay}
                  >
                    <span className={styles.linkText}>Pathways</span>
                  </Link>
                </li>
              )}
              {hasSelfServePlaylist(currentUser) && (
                <li>
                  <Link
                    to="/me/playlists"
                    className={styles.link}
                    onClick={hideOverlay}
                  >
                    <span className={styles.linkText}>Playlists</span>
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to="/bookmarks/"
                  className={styles.link}
                  onClick={hideOverlay}
                >
                  <span className={styles.linkText}>Bookmarks</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/goals/"
                  className={styles.link}
                  onClick={hideOverlay}
                >
                  <span className={styles.linkText}>Learning goals</span>
                </Link>
              </li>

              {shouldShowOffPlatformResourceLink(
                currentUser,
                offPlatformResourceButtonPosition,
              ) && (
                <li>
                  <Link
                    to="/request/resource-not-on-learnerbly/"
                    className={styles.link}
                    onClick={hideOverlay}
                  >
                    <span className={styles.linkText}>
                      Resource not on Learnerbly?
                    </span>
                  </Link>
                </li>
              )}

              {isAdmin(currentUser) && (
                <li>
                  <div className={styles.link} onClick={copyToken}>
                    <span className={styles.linkText}>Copy Token</span>
                  </div>
                </li>
              )}
              {shouldShowResetDemoDataOption && (
                <li>
                  <div
                    className={styles.link}
                    onClick={() => onResetDemoData()}
                  >
                    <span className={styles.linkText}>Reset demo data</span>
                  </div>
                </li>
              )}
            </ul>
          </div>
          <div className={styles.footer}>
            <Link
              to="/logout/"
              className={styles.signout}
              onClick={hideOverlay}
            >
              <span className={styles.linkText}>Sign out</span>
            </Link>
          </div>
        </nav>
      </div>
    </OutsideClickHandler>
  );
};

export default ProfileNavigationWidget;
